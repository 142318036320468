<template>
  <div class="Investigate">
    <div class="form_info">
      <el-form ref="form" :model="queryInfo" label-width="80px">
        <div class="form">
          <div class="comble_txt">
            <el-form-item label="项目状态:" prop="">
              <el-radio-group v-model="queryInfo.projectStatus" @change="search">
                <el-radio-button label="">不限 </el-radio-button>
                <el-radio-button label="1">需求确认阶段 </el-radio-button>
                <el-radio-button label="2">企业对接阶段 </el-radio-button>
                <el-radio-button label="3">项目进行阶段 </el-radio-button>
                <el-radio-button label="4">项目总结阶段 </el-radio-button>
                <el-radio-button label="5">项目后阶段 </el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="所在城市:" prop="">
              <el-select v-model="queryInfo.city" placeholder="请选择省份" @change="citychange">
                <el-option :value="''" label="全部"></el-option>
                <el-option
                  v-for="item in operatingList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.name"
                >
                </el-option>
              </el-select>
              <div style="display: inline-block; width: 10px"></div>
              <el-select v-model="queryInfo.region" placeholder="请选择地区" @change="search">
                <el-option :value="''" label="全部"></el-option>
                <el-option
                  v-for="item in operatingLists"
                  :key="item.id"
                  :label="item.name"
                  :value="item.name"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label=" " label-width="0px">
              <div class="input-with">
                <div>
                  <el-button type="primary" icon="el-icon-plus" @click="handleAdd">新增</el-button>
                </div>
                <div>
                  <el-input
                    placeholder="请输入项目名称"
                    v-model="queryInfo.projectName"
                    class="input-with-select"
                  >
                    <el-button class="seach" slot="append" @click="search()">搜索</el-button>
                  </el-input>
                </div>
              </div>
            </el-form-item>
          </div>
        </div>
      </el-form>
    </div>

    <div class="tab_txt" v-loading="loading" :element-loading-text="loadingtxt">
      <span style="position: relative; top: -10px">当前条件下共查处{{ total }}个项目</span>
      <div class="storeCenter_item" v-for="(item, index) in listData" :key="index">
        <div class="storeCenter_item_top">
          <div class="storeCenter_item_top_left">
            <img v-if="item.projectImg" :src="item.projectImg" alt="" />
            <img v-else src="../../assets/image/Group 3163.png" alt="" />
            <div class="storeCenter_item_top_left_flex">
              <div style="display: flex; align-items: center">
                <div v-if="index == 0" class="new_set">
                  <span>最新</span>
                </div>
                <h4 style="font-size: 18px">{{ item.projectName }}</h4>
              </div>
              <div class="storeCenter_item_top_left_flex_tag">
                <span v-for="(o, index) in item.areasInvestigation" :key="index">
                  <span v-if="index < 1" class="office">{{ o.areas[1] }}</span>
                </span>
              </div>
            </div>
          </div>
          <div class="storeCenter_item_top_center">
            <el-steps :active="item.projectStatus" align-center>
              <el-step title="需求确认阶段"></el-step>
              <el-step title="企业对接阶段"></el-step>
              <el-step title="项目进行阶段"></el-step>
              <el-step title="项目总结阶段"></el-step>
              <el-step title="项目后阶段"></el-step>
            </el-steps>
          </div>
          <div class="storeCenter_item_top_right">
            <div class="storeCenter_item_top_right_flex">
              <div class="num">{{ item.inspectNumber }}</div>
              <div class="text">考察人数</div>
            </div>
            <div class="storeCenter_item_top_right_flex" @click="companyclick(item)">
              <div class="num">{{ item.companyCount }}</div>
              <div class="text">参访企业</div>
              <div class="position_new" v-if="item.recentlyAdded">
                <span>最近新增:{{ item.recentlyAdded }}</span>
              </div>
            </div>
            <div class="storeCenter_item_top_right_flex" @click="particClick(item)">
              <div class="num">{{ item.participantsCount }}</div>
              <div class="text">参访嘉宾</div>
            </div>
            <div class="storeCenter_item_top_right_flex" @click="contactUsClick(item)">
              <div class="num">{{ item.contactUsCount }}</div>
              <div class="text">需要联系的客户</div>
            </div>
          </div>
        </div>
        <div class="storeCenter_item_bottom">
          <div class="storeCenter_item_bottom_left">
            <div class="bottom_txt">
              <div style="margin-left: 5px">
                <span style="position: relative; left: -5px; top: 2px">
                  <img src="../../assets/image/jr-icon-zhong.png" width="16" height="16" alt="" />
                </span>
                <span>时间：{{ item.startTime }}至{{ item.endTime }}</span>
              </div>

              <div class="interval">
                <span style="position: relative; left: -5px; top: 2px">
                  <img
                    src="../../assets/image/jr-icon-xingxing.png"
                    width="14"
                    height="14"
                    alt=""
                  />
                </span>
                <span>需求提交人:{{ item.realName }}</span>
                <span v-if="item.name">({{ item.name }})</span>
                <span v-if="item.job"> ({{ item.job }})</span>
              </div>
              <div class="interval">
                <span style="position: relative; left: -5px; top: 2px">
                  <img src="../../assets/image/jr-icon-dianhua.png" width="14" height="14" alt="" />
                </span>
                <span>联系方式：{{ item.phone }}</span>
              </div>
            </div>
          </div>
          <div class="storeCenter_item_bottom_right">
            <el-button type="primary" plain size="mini" @click="EnterpriseClick(item)"
              >配置企业</el-button
            >
            <el-button type="primary" plain size="mini" @click="travelClick(item)"
              >配置行程</el-button
            >
            <el-button type="primary" plain size="mini" @click="reportsClick(item)"
              >配置报告</el-button
            >
            <el-button type="text" size="mini" @click="editclick(item)">编辑</el-button>
            <el-button type="text" size="mini" @click="upproject(item)">修改项目阶段</el-button>
            <el-button type="text" size="mini" @click="Editrecord">修改记录</el-button>
            <el-button type="text" size="mini" @click="deldemandClick(item)" style="color: #ff5454"
              >删除</el-button
            >
          </div>
        </div>
      </div>
      <div class="new_page">
        <el-pagination
          :current-page="queryInfo.pageNum"
          :page-sizes="[10, 30, 50]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
    <el-dialog title="修改项目阶段" :visible.sync="open" width="500px" append-to-body>
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="项目状态" prop="">
          <el-select v-model="form.projectStatus" placeholder="请选择项目状态">
            <el-option
              v-for="item in distribution_mode"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
    <!-- <el-dialog title="修改记录" :visible.sync="flag" append-to-body>
      <div style="width: 400px; padding: 20px 0px">
        <el-input
          placeholder="请输入用户昵称"
          v-model="queryInfo.companyName"
          class="input-with-select"
        >
          <el-button
            style="
              border: 1px solid #448aff;
              border-radius: 0;
              background-color: #448aff;
              color: #fff;
            "
            slot="append"
            @click="search()"
            >搜索</el-button
          >
        </el-input>
      </div>
      <el-table ref="multipleTable" :data="historyList" row-key="buildingId">
        <el-table-column type="selection" width="50" align="center" :reserve-selection="true" />
        <el-table-column label="用户名称" align="center" prop="returnable" />
        <el-table-column label="记录时间" align="center" prop="noReturnable" />
        <el-table-column label="记录事件" align="center" prop="createTime" />
      </el-table>
      <div class="new_page">
        <el-pagination
          :current-page="hisparam.pageNum"
          :page-sizes="[10, 30, 50]"
          :page-size="hisparam.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="histotal"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </el-dialog> -->
  </div>
</template>

<script>
import {
  demandlist,
  getByFatherIdt,
  getmatching,
  getProvince,
  editType,
  getdemand,
  deldemand
} from '@/api/demand'
import { Loading } from 'element-ui'
const defaultQueryInfo = Object.freeze({
  pageNum: 1,
  pageSize: 10,
  city: null, //所在
  projectName: null, //项目名称
  projectStatus: '', //项目状态
  region: null //所在
})
export default {
  name: 'Investigate',
  data() {
    return {
      queryInfo: { ...defaultQueryInfo },
      hisparam: {},
      histotal: 0,
      listData: [],
      // 遮罩层
      loading: false,
      loadingtxt: '',
      // 总条数
      total: 0,
      //打开修改项目阶段
      open: false,
      //打开修改记录
      flag: false,
      form: {
        active: ''
      },
      operatingLists: [],

      operatingList: [],
      historyList: [],
      distribution_mode: [
        {
          value: '1',
          label: '需求确认阶段'
        },
        {
          value: '2',
          label: '企业对接阶段'
        },
        {
          value: '3',
          label: '项目进行阶段'
        },
        {
          value: '4',
          label: '项目总结阶段'
        },
        {
          value: '5',
          label: '项目后阶段'
        }
      ]
    }
  },
  created() {
    this.search()
    this.incity()
  },
  methods: {
    //进去城市
    async getByFatherIdt(id) {
      const res = await getByFatherIdt({ provinceCode: id })
      if (res.data.resultCode == 200) {
        this.operatingLists = res.data.data
      }
    },
    //城市
    async incity() {
      const res = await getProvince()
      if (res.data.resultCode == 200) {
        this.operatingList = res.data.data
      }
    },
    citychange() {
      this.operatingList.forEach((o) => {
        if (this.queryInfo.city == o.name) {
          this.queryInfo.region = ''
          this.getByFatherIdt(o.code)
        }
      })
      this.search()
    },
    //项目一览
    async search() {
      this.loading = true
      const res = await demandlist(this.queryInfo)
      if (res.data.resultCode == 200) {
        this.listData = res.data.data.list
        this.total = res.data.data.total
        this.loading = false

        this.listData.forEach((o) => {
          o.projectStatus = o.projectStatus * 1
          o.areasInvestigation = JSON.parse(o.areasInvestigation)
        })
      }
    },
    handleAdd() {
      this.$router.push({
        path: '/projectcenter/addinvestigate'
      })
    },
    //进入配置企业
    async EnterpriseClick(item) {
      this.loading = true
      this.loadingtxt = '第一次匹配花费的时间会较长，请稍后...'
      const res = await getmatching({ inspectDemandId: item.id })
      if (res.data.resultCode == 200) {
        this.loading = false
        this.$router.push({
          name: 'ConfigureEnterprise',
          query: { inspectDemandId: item.id }
        })
      } else {
        this.loading = false
        this.$message.warning(res.data.message)
      }

      // this.$message.warning('开发中！')
    },
    //进入配置行程
    travelClick(item) {
      this.$router.push({
        name: 'TravelConfiguration',
        query: { inspectDemandId: item.id }
      })
    },

    //参访企业
    companyclick(item) {
      this.$router.push({
        name: 'visitingCompany',
        query: { inspectDemandId: item.id }
      })
    },
    // 分页
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.search()
      // console.log(`每页 ${val} 条`)
    },
    // 分页
    handleCurrentChange(val) {
      this.queryInfo.pageNum = val
      this.search()
      // console.log(`当前页: ${val}`)
    },
    //打开修改项目阶段
    async upproject(item) {
      const res = await getdemand(item.id)
      if (res.data.resultCode == 200) {
        this.form = res.data.data
        this.open = true
      }
    },
    //确定修改项目状态
    async submitForm() {
      const res = await editType(this.form)
      if (res.data.resultCode == 200) {
        this.open = false
        this.search()
      }
    },
    cancel() {
      this.open = false
    },
    //修改记录
    Editrecord() {
      this.flag = true
    },
    //编辑
    editclick(item) {
      this.$router.push({
        name: 'addinvestigate',
        query: { id: item.id }
      })
    },
    //配置报告
    reportsClick(item) {
      this.$router.push({
        name: 'ConfiguringReports',
        query: { inspectDemandId: item.id }
      })
    },
    //参访嘉宾
    particClick(item) {
      this.$router.push({
        name: 'addinvestigate',
        query: { id: item.id, flag: 2 }
      })
    },
    //联系客户数
    contactUsClick(item) {
      this.$router.push({
        name: 'contactUs',
        query: { id: item.id }
      })
    },
    //删除项目
    deldemandClick(row) {
      const ids = row.id
      this.$alert('您是否确认删除？')
        .then(function () {
          return deldemand(ids)
        })
        .then(() => {
          this.search()
          this.$message.success('删除成功')
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep .el-step__title {
  font-size: 12px;
  line-height: 38px;
}

::v-deep .el-table th.el-table__cell {
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #f7f8fa;
}
.input-with-select {
  width: 400px;
}
.new_page {
  margin: 20px auto;
  width: 100%;
  text-align: center;
}

.Investigate {
  .form_info {
    background: #ffffff;
    padding: 20px;
  }

  .tab_txt {
    margin-top: 20px;
  }

  .span_div {
    font-size: 14px;
    color: #7c7f8e;
    margin-right: 12px;
  }

  .input-with {
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    padding: 0px 10px;

    .seach {
      background-color: #448aff;
      color: #fff;
      border-radius: 0;
      border: 1px solid #448aff;
    }
  }

  .in_table {
    width: 100%;
    margin-top: 20px;

    .img_url {
      width: 42px;
      height: 42px;
      border-radius: 4px;
    }
  }

  .storeCenter_item {
    width: 100%;
    overflow: hidden;
    margin-bottom: 15px;
    display: flex;
    border-radius: 4px;
    flex-direction: column;
    box-sizing: border-box;
    margin-bottom: 20px;
    background: #ffffff;
    padding: 20px 20px 0px 20px;
    .storeCenter_item_top {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      box-sizing: border-box;
      padding-bottom: 14px;
      position: relative;
      border: 1px solid #efefef;
      padding: 15px 0px 15px 15px;
      position: relative;

      .storeCenter_item_top_left {
        display: flex;
        flex-direction: row;

        img {
          width: 64px;
          height: 64px;
          border-radius: 4px;
          margin-right: 14px;
        }

        .storeCenter_item_top_left_flex {
          flex: 1;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .new_set {
            font-size: 13px;
            color: #fff;
            padding: 5px 6px 3px 6px;
            background: #ff5151;
            border-radius: 2px;
            text-align: center;
            margin-right: 10px;
          }
          h4 {
            color: #1f2d3d;
            font-size: 26px;
            font-weight: 500;
            margin: 0;
          }

          .storeCenter_item_top_left_flex_tag {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-content: center;
            padding-top: 13px;

            span {
              box-sizing: border-box;
              padding: 1px 6px;
              display: block;
              font-size: 14px;
              font-weight: 400;
              border-radius: 4px;
            }

            .office {
              position: relative;
              left: -3px;
              padding: 5px;
              color: #6090ee;
              background: #e1ecff;
            }

            .support {
              border: 1px solid #fd523f;
              color: #fd523f;
              margin-left: 12px;
            }
          }
        }
      }

      .storeCenter_item_top_right {
        padding: 0px 15px;
        display: flex;
        flex-direction: row;
        align-content: center;
        position: relative;
        .storeCenter_item_top_right_flex {
          display: flex;
          cursor: pointer;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          box-sizing: border-box;
          padding: 10px 12px;
          overflow: hidden;

          .position_new {
            position: absolute;
            top: -9px;
            right: 210px;
            padding: 7px 5px 5px 5px;
            color: #fff;
            font-size: 12px;
            background: #ff8832;
            border-radius: 4px 4px 4px 0px;
          }
          .num {
            color: #448aff;
            font-size: 24px;
            font-weight: 500;
          }

          .text {
            padding-top: 5px;
            color: #1f2d3d;
            font-size: 16px;
            font-weight: 400;
          }
        }
      }
      .storeCenter_item_top_center {
        width: 450px;
        width: 35%;
        height: 50%;
        position: absolute;
        transform: translateY(-50%);
        transform: translateX(70%);
      }
    }
    .storeCenter_item_bottom {
      box-sizing: border-box;
      width: 100%;
      overflow: hidden;
      padding-top: 10px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 15px 0px;
      .storeCenter_item_bottom_left {
        .bottom_txt {
          display: flex;
          padding: 5px 0px;
          .interval {
            padding-left: 30px;
          }
          span {
            font-size: 14px;
            i {
              color: #2699ff;
            }
          }
        }
      }

      .storeCenter_item_bottom_right {
        display: flex;
      }
    }
  }
}
</style>
